@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Formula Condensed';
  src: url('assets/fonts/Formula-Condensed/FormulaCondensed-Light.otf') format('truetype');
}

@font-face {
  font-family: 'PP Mori';
  src: url('assets/fonts/PP-Mori/PPMori-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v109/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format('woff2');
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.ppmori {
  font-family: 'PP Mori', sans-serif !important;
}

body {
  font-family: 'Formula Condensed', sans-serif !important;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

::-webkit-scrollbar {
  display: none;
}